@import 'styles/layers.scss';

@layer component {
  .dialog {
    width: 1020px;
    min-width: 1020px;
    background: transparent;
  }
  
  .body {
    padding: 20px 0 20px 20px;
    height: 580px;
    overflow-y: auto;
  }
  
  .content {
    height: calc(100% - var(--bs-modal-margin) * 2);
  }
  
  @media screen and (max-width: 1220px) {
    .dialog {
      min-width: 1110px;
    }
  }
}
